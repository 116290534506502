<template>
  <div class="responses">
    <ModalWindow
      v-if="showApprovalHistoryModal"
      @close="showApprovalHistoryModal = false"
      class="responses__approval-history-modal"
    >
      <ApprovalHistory
        :id="approvalHistoryId"
        @close="showApprovalHistoryModal = false"
      />
    </ModalWindow>
    <ChatWindow
      v-if="showChat"
      :responseId="chatId"
      :setRoomId="chatRoomId"
      @close="showChat = false"
    />

    <VBreadcrumbs
      v-if="$route.params.id"
      :customRouteIndex="1"
      :customRouteLink="`/vacancies/${$route.params.id}`"
      class="responses__breadcrumbs"
    />
    <MainHeader
      class="responses__header"
      :class="{ responses__header_vacancy: $route.params.id }"
      v-if="responsesList?.data?.items"
      :title="'Задачи'"
      :number="
        responsesList?.data?.items.length
          ? responsesList?.data?.count?.current
          : ''
      "
    />

    <div class="responses__row">
      <VSegmentedControl
        :items="filteringOptions"
        :modelValue="activeFilter"
        @update:modelValue="filtration"
        class="responses__segmented-control"
        :class="{
          'responses__segmented-control_full': route.meta.role !== 'HR',
        }"
      />

      <VDefaultInput
        v-model="search"
        @update:modelValue="responsesSearch"
        placeholder="Поиск"
        border
        class="responses__search"
      >
        <template #iconLeft>
          <img src="@/assets/images/icons/search.svg" alt="search" />
        </template>
        <template #iconRight>
          <div
            class="companies__search-clear"
            v-if="search"
            @click="clearSearch"
          >
            <img src="@/assets/images/icons/x.svg" alt="search" />
          </div>
        </template>
      </VDefaultInput>
    </div>

    <div class="responses__content-wrapper">
      <ResponseSidebar
        class="responses__sidebar"
        v-if="responsesList?.data?.items"
        :responsesData="responsesList"
        :role="role"
        v-model="sidebarFilters"
      />
      <div class="responses__content" v-if="responsesList?.data?.items">
        <ResponseHeaderSort
          class="responses__response-header-sort"
          :modelValue="sortOrder"
          @update:modelValue="onSortOrderUpdate"
        />
        <div
          class="responses__cards-wrapper"
          v-if="responsesList?.data?.items?.length"
        >
          <div class="responses__cards">
            <ResponseCard
              class="responses__card"
              v-for="response in responsesList?.data?.items"
              :key="response.id"
              :response="response"
              :chatIsAvailable="responsesList?.data?.chats_is_available"
              @openHistory="openApprovalHistory(response.id)"
              @openChat="openChat(response.id)"
            />
          </div>
        </div>

        <div
          class="responses__no-content"
          v-if="!responsesList?.data.items.length"
        >
          <VNoContent
            v-if="!search"
            :title="textNoContent"
            description="По выбранному фильтру ничего не найдено"
          />
          <VNoContent
            v-if="search"
            title="Ничего не найдено"
            description="Проверьте пожалуйста, правильно ли вы написали название"
          />
        </div>
        <div
          class="responses__pagination"
          v-if="false && responsesList?.data?.count?.current > 1 && showPagination"
        >
          <VButton
            class="responses__pagination-btn"
            label="Загрузить еще"
            bg="#0D5FCB1A"
            color="#0086B2"
            bgHover="#0D5FCB4D"
            colorHover="#0086B2"
            @click="loadMore(page)"
            v-if="
              page < responsesList?.data?.count?.current && showPagination
            "
          />
          <VPaginate
            :pageCount="responsesList?.data?.count?.current"
            :modelValue="page"
            @update:modelValue="pagination"
            class="responses__pagination-pag"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onActivated, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import responses from "@/api/responses";

import MainHeader from "@/components/MainHeader";
import VPaginate from "@/components/UI/VPaginate";
import ResponseCard from "@/components/ResponseCard";
import ChatWindow from "@/components/ChatWindow";
import ResponseSidebar from "@/components/ResponseSidebar";
import ResponseHeaderSort from "@/components/ResponseHeaderSort";
import ApprovalHistory from "@/components/ModalWindow/ModalBodyes/ApprovalHistory";
import { debounce } from "@/services/helpers";
const route = useRoute();

const store = useStore();

const role = store.getters["auth/role"];

const vacancyId = ref(route.params.id);
const filteringOptions = ref([]);

const showApprovalHistoryModal = ref(false);
const approvalHistoryId = ref(null);
const showChat = ref(false);
const chatId = ref(null);
const chatRoomId = ref(null);
const response = ref(null);
const textNoContent = ref("Список откликов пуст");
const responsesList = ref({});

const activeFilter = ref(null);
const showPagination = ref(true);

const search = ref("");
const page = ref(1);
const paginationFilter = ref({
  "page[offset]": 0,
  "page[limit]": 24,
});
const element = ref();
const observer = ref();
const sidebarFilters = ref({
  stepFilterId: null,
  approvalId: null,
  vacancyId: null,
  state: null,
  status: null,
  isAccepted: null,
  isCancelled: null,
});
const sortOrder = ref({});
if (route.meta.role !== "HR") textNoContent.value = "Список задач пуст";

const idToLabelsMap = ref({
  null: "Все",
  unprocessed: "Необработанные",
  expired: "Срок истёк",
  processed: "Отработанные",
});

if (role === "HR" || role === "MAIN_HR") {
  filteringOptions.value = [
    {
      id: null,
      label: "Все",
    },
    {
      id: "unprocessed",
      label: "Необработанные",
    },
    {
      id: "expired",
      label: "Срок истёк",
    },
    {
      id: "processed",
      label: "Отработанные",
    },
  ];
} else if (role === "APPROVING_PERSON") {
  filteringOptions.value = [
    {
      id: null,
      label: "Все",
    },
    {
      id: "unprocessed",
      label: "Необработанные",
    },
    {
      id: "expired",
      label: "Срок истёк",
    },
    {
      id: "processed",
      label: "Отработанные",
    },
  ];
} else {
  filteringOptions.value = [
    {
      id: null,
      label: "Все",
    },
    {
      id: "unprocessed",
      label: "Новые",
    },
    {
      id: "expired",
      label: "Дедлайн",
    },
  ];
}

const debounceFetchResponses = debounce(
  (isAdd = false) => fetchResponses({},isAdd),
  400
);

const observerCallback = function (entries, observer) {
  const entry = entries[0];
  if (entry?.isIntersecting) {
    if(responsesList.value?.data?.items?.length < responsesList?.value?.data?.count?.current){
      debounceFetchResponses({},true);
    }
    observer.unobserve(element.value);
  }
};

const openApprovalHistory = (id) => {
  approvalHistoryId.value = id;
  showApprovalHistoryModal.value = true;
};

const fetchResposne = async (id) => {
  return (response.value = await responses.getResponse(id));
};
const formatHeaderFilters = (responsedFilterHeaders = {}) => {
  const headerFilters = Object.entries(responsedFilterHeaders)
    .map(([key, val]) => {
      if (idToLabelsMap.value?.[key]) {
        return {
          id: key,
          label: idToLabelsMap.value[key],
          count: val,
          incoming: val?.not_viewed,
        };
      }
    })
    .filter((item) => item);
  filteringOptions.value =
    role !== "APPROVING_PERSON"
      ? [
          {
            id: null,
            label: "Все",
          },
          ...headerFilters,
          {
            id: "processed",
            label: "Обработанные",
          },
        ]
      : [
          {
            id: null,
            label: "Все",
          },
          ...headerFilters,
        ];
  const indexOfIgnoring = filteringOptions.value.findIndex(
    (filter) => filter.id === "is_delayed"
  );
  if (indexOfIgnoring) {
    filteringOptions.value.splice(indexOfIgnoring, 1);
  }
};
const fetchResponses = async (outerParams = {},isAdd = false) => {

  if (isAdd) {
    paginationFilter.value["page[offset]"] += 24;
  } else {
    paginationFilter.value["page[offset]"] = 0;
  }

  const params = {
    ...(outerParams && outerParams),
    search: search.value ? search.value : "",
    "page[offset]": paginationFilter.value["page[offset]"],
    "page[limit]": paginationFilter.value["page[limit]"],
    ...(activeFilter.value && { state: activeFilter.value }),
    ...(sidebarFilters.value?.vacancyId && {
      vacancy_id: sidebarFilters.value?.vacancyId,
    }),
    ...(sidebarFilters.value?.status && {
      status: sidebarFilters.value?.status,
    }),
    ...(sidebarFilters.value?.vacancy_route_id && {
      vacancy_route_id: sidebarFilters.value?.vacancy_route_id,
    }),
    ...(sidebarFilters.value?.step && { step: sidebarFilters.value?.step }),
  };

  try {
    const response = await responses.getResponses(
      params,
      sortOrder.value?.sort
    );
    if (isAdd) {
      if (response?.data && response?.data?.items?.length > 0) {
        responsesList.value.data.items.push(...response?.data?.items);
      } else {
        observer.value.unobserve(element.value);
        return;
      }
    } else {
      responsesList.value = response;
    }

    formatHeaderFilters(response?.data?.count);
    showPagination.value = true;
  } catch (error) {
    console.log(error);
  }

  nextTick(() => {
      let lastElement = null;
      element.value = document.querySelector(".responses__card:last-child");
      observer.value = new IntersectionObserver(observerCallback, {
        threshold: 0.5,
      });
      lastElement = element.value;
      if (element.value) observer.value.observe(element.value);
    });
};
const loadMore = async (num) => {
  showPagination.value = false;
  paginationFilter.value["page[offset]"] += num
    ? num
    : paginationFilter.value["page[offset]"] + 1;

  const params = {
    search: search.value ? search.value : "",
    "page[offset]": paginationFilter.value["page[offset]"],
    "page[limit]": paginationFilter.value["page[limit]"],
    ...(activeFilter.value && { [activeFilter.value]: 1 }),
    ...(sidebarFilters.value?.vacancyId && {
      vacancy_id: sidebarFilters.value?.vacancyId,
    }),
    ...(sidebarFilters.value?.approvalId && {
      filter_approval_id: sidebarFilters.value?.approvalId,
    }),
    ...(sidebarFilters.value?.stepFilterId && {
      filter_step_id: sidebarFilters.value?.stepFilterId,
    }),
    ...(sidebarFilters.value?.isAccepted && {
      is_accepted: sidebarFilters.value?.isAccepted,
    }),
    ...(sidebarFilters.value?.isCancelled && {
      is_cancelled: sidebarFilters.value?.isCancelled,
    }),
  };

  try {
    const response = await responses.getResponses(
      params,
      sortOrder.value?.sort
    );
    formatHeaderFilters(response?.data?.count);
    if (num) responsesList.value.responses.data.items = [];
    responsesList.value.responses.data.items.push(
      ...response.responses?.data.items
    );
    showPagination.value = true;
  } catch (error) {
    console.log(error);
  }
};

const getResponses = async (params) => {
  try {
    const response = await responses.getResponses(params);
    responsesList.value = response;
    formatHeaderFilters(response?.data?.count);
  } catch (error) {
    console.log(error);
  }
};

const denouncedGetResponses = debounce((params) => fetchResponses(params), 300);
const responsesSearch = () => {
  if (search.value.length < 3) {
    const params = {
      search: "",
    };
    if (vacancyId.value) params.vacancy_id = vacancyId.value;

    if (activeFilter.value !== null) params[activeFilter.value] = 1;
    denouncedGetResponses(params);
    return;
  }
  const params = {
    search: search.value,
  };
  if (vacancyId.value) params.vacancy_id = vacancyId.value;

  if (activeFilter.value !== null) params[activeFilter.value] = 1;
  denouncedGetResponses(params);
};

const filtration = (val) => {
  if (val === activeFilter.value) return;
  activeFilter.value = val;
  search.value = "";
  page.value = 0;
  paginationFilter.value["page[offset]"] = 0;

  const params = {
    [val]: 1,
  };

  if (vacancyId.value) params.vacancy_id = vacancyId.value;
  if (sidebarFilters.value.status) {
    sidebarFilters.value.status = null;
    return;
  }

  fetchResponses();
};

const pagination = (val) => loadMore(val);

const clearSearch = () => {
  search.value = "";

  const params = {};
  if (vacancyId.value) params.vacancy_id = vacancyId.value;
  if (activeFilter.value !== null) params[activeFilter.value] = 1;
  getResponses(params);
};

const openChat = (id) => {
  fetchResposne(id).then((data) => {
    chatId.value = id;
    chatRoomId.value = response.value?.chat_room_id;
    showChat.value = true;
  });
};

const onSortOrderUpdate = (sort, field) => {
  sortOrder.value = { sort, field };
  fetchResponses();
};
watch(
  sidebarFilters,
  () => {
    if (
      activeFilter.value !== null &&
      (sidebarFilters.value?.stepFilterId ||
        sidebarFilters.value?.approvalId ||
        sidebarFilters.value?.isAccepted ||
        sidebarFilters.value?.isCancelled ||
        !sidebarFilters.value?.vacancyId ||
        sidebarFilters.value?.vacancyId !== null)
    ) {
    }
    if (sidebarFilters.value?.status) {
      activeFilter.value = null;
    }
    fetchResponses();
  },
  { deep: true }
);

watch(
  () => route.path,
  () => {
    if (route.path === "/responses") {
      fetchResponses();
    }
  }
);

onActivated(() => {
  if (route.params.id) {
    sidebarFilters.value.vacancyId = route.params.id;
    fetchResponses({
      vacancy_id: route.params.id,
    });
  } else {
    if (route.query?.vacancyId) {
      sidebarFilters.value.vacancyId = Number(route.query?.vacancyId);
      fetchResponses({ vacancy_id: route.query?.vacancyId });
    } else {
      fetchResponses();
    }
  }
});
</script>

<style scoped lang="scss">
.responses {
  padding-bottom: 57px;

  &__breadcrumbs {
    margin-top: 24px;
  }

  &__header {
    margin-top: 32px;

    &_vacancy {
      margin-top: 16px;
    }
  }

  &__row {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__segmented-control {
    &::v-deep(.v-filtration__filter) {
      width: 182px;
      margin-left: 0;
    }

    &_full {
      &::v-deep(.v-filtration__filter) {
        width: 202.33px;
      }
    }
  }

  &__search {
    width: 352px;

    &::v-deep(.companies__search-clear) {
      height: 20px !important;
    }

    &::v-deep(.v-input__input) {
      &::placeholder {
        color: $dark-blue;
        opacity: 1;
      }
    }
  }

  &__content {
    width: 832px;
  }

  &__cards {
    margin-left: 32px;
    width: 100%;
    height: calc(100% - 206px);
    margin-top: 12px;
    display: flex;
    flex-direction: column;
  }

  &__card {
    width: 100%;

    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__no-content {
    width: 100%;
    height: calc(
      100vh - 56px - 32px - 34px - 24px - 48px - 32px - 40px - 57px - 57px
    );
    max-height: calc(475px - 40px);
    display: flex;
    justify-content: center;
  }

  &__pagination {
    position: relative;
    width: 100%;
    height: 48px;
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }

  &__pagination-btn {
    width: 156px;
    padding: 0 10px;
    border-radius: 8px;

    &:active {
      background-color: #0d5fcb80 !important;
      color: $blue !important;
    }
  }

  &__pagination-pag {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__approval-history-modal {
    &::v-deep(.modal__body) {
      width: 928px;
    }
  }

  &__content-wrapper {
    margin-top: 32px;
    display: flex;
  }

  &__sidebar {
    width: 256px;
  }

  &__response-header-sort {
    margin-left: 32px;
  }
}
</style>
