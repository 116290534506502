<template>
  <div class="response-sidebar">
    <div
      :class="[
        'response-sidebar__top-block',
        { 'response-sidebar__top-block_bordered': false },
      ]"
    >
      <div class="response-sidebar__title">Вакансия</div>
      <VSelect
        class="response-sidebar__select"
        placeholder="Выберите вакансию"
        :options="arrayTypedVacancies"
        @update:modelValue="onVacancyTypeSelect"
        v-model="selectedFilters.vacancyId"
      />

      <div
        v-if="currentVacancy?.routes?.length"
        class="response-sidebar__title-route"
      >
        Маршрут согласования
      </div>
      <div class="response-sidebar__btns">
        <VSelect
          class="response-sidebar__select-routes"
          v-if="currentVacancy?.routes?.length"
          placeholder="Выберите маршрут"
          :options="formateRoutes(currentVacancy?.routes)"
          @update:modelValue="selectRouteFilter"
          v-model="selectedFilters.vacancy_route_id"
        />
      </div>
    </div>
    <div
      class="response-sidebar__bottom-block"
      v-if="role !== 'APPROVING_PERSON'"
    >
      <div class="response-sidebar__bottom-buttons">
        <div class="response-sidebar__title">Воронка по этапам</div>
        <template v-if="responsesData?.data?.filter?.stages?.length">
          <div
            @click="selectStage(null)"
            class="response-sidebar__btn"
            :class="{
              'response-sidebar__btn_active': selectedFilters.step === null,
            }"
          >
            <div class="response-sidebar__btn_with-fio">
              <div class="response-sidebar__btn-cell">
                <div class="response-sidebar__btn-fio">
                  {{ "Все" }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="stage in responsesData?.data?.filter?.stages"
            :key="stage?.stage"
            @click="selectStage(stage?.step)"
            class="response-sidebar__btn"
            :class="{
              'response-sidebar__btn_active':
                selectedFilters.step === stage?.step,
            }"
          >
            <div
              class="response-sidebar__btn_with-fio"
              v-if="!stage?.responsible"
            >
              <div class="response-sidebar__btn-cell">
                <div class="response-sidebar__btn-fio">
                  {{ stage?.title }}
                </div>
              </div>
              <div class="response-sidebar__btn-num">
                {{ stage?.count || 0 }}
              </div>
            </div>
            <div class="response-sidebar__btn_with-fio" v-else>
              <div class="response-sidebar__btn-cell">
                <div class="response-sidebar__btn-label">
                  {{ stage?.title }}
                </div>
                <div class="response-sidebar__btn-fio">
                  {{ stage?.responsible }}
                </div>
              </div>
              <div class="response-sidebar__btn-num">
                {{ stage?.count || 0 }}
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="response-sidebar__footer-buttons">
        <div
          @click="selectStatusFilter('accepted')"
          class="response-sidebar__btn"
          :class="{
            'response-sidebar__btn_active':
              selectedFilters?.status === 'accepted',
          }"
        >
          <div class="response-sidebar__btn-title">Принятые</div>
          <div class="response-sidebar__btn-num">
            {{ responsesData?.data?.count?.accepted }}
          </div>
        </div>
        <div
          @click="selectStatusFilter('rejected')"
          class="response-sidebar__btn"
          :class="{
            'response-sidebar__btn_active':
              selectedFilters?.status === 'rejected',
          }"
        >
          <div class="response-sidebar__btn-title">Отказанные</div>
          <div class="response-sidebar__btn-num">
            {{ responsesData?.data?.count?.rejected }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";

const emit = defineEmits([
  "update:modelValue",
  "filterSelected",
  "vacancyTypeSelect",
  "vacancyStepSelect",
  "vacancyApprovalFilterSelect",
]);
const props = defineProps({
  responsesData: {
    type: Object,
    required: true,
  },
  role: {
    type: String,
  },
  modelValue: {
    type: Object,
    required: true,
  },
});

const selectedFilters = ref({
  stepFilterId: null,
  approvalId: null,
  vacancyId: null,
  isAccepted: null,
  isCancelled: null,
  status: null,
  vacancy_route_id: null,
  step: null,
  accepted: null,
  rejected: null,
});

const currentVacancy = computed(() => {
  return props?.responsesData?.data?.filter?.vacancies.find(
    (vacancy) => vacancy?.id === selectedFilters.value?.vacancyId
  );
});
const arrayTypedVacancies = computed(() => {
  let formatedArr = Object.entries(
    props?.responsesData?.data?.filter?.vacancies || {}
  ).map(([key, val]) => {
    return { id: val?.id, label: val?.title };
  });
  return [{ id: null, label: "Все" }, ...formatedArr];
});

const formateRoutes = computed(() => (routes) => {
  let formatedRoutes;
  formatedRoutes = routes?.length
    ? routes.map((route) => ({
        ...route,
        id: route?.id,
        label: route?.name || "Нет названия",
      }))
    : [];

  return [{ id: null, label: "Все" }, ...formatedRoutes];
});

const selectStepFilter = (stepId) => {
  selectedFilters.value.stepFilterId = stepId;
  selectedFilters.value.isAccepted = null;
  selectedFilters.value.isCancelled = null;
};

const selectRouteFilter = (routeId) => {
  selectedFilters.value.vacancy_route_id = routeId;
  selectedFilters.value.isAccepted = null;
  selectedFilters.value.isCancelled = null;
};

const onVacancyTypeSelect = (type) => {
  selectedFilters.value.vacancyId = type;
  selectedFilters.value.approvalId = null;
  selectedFilters.value.stepFilterId = null;
  selectedFilters.value.step = null;
  selectedFilters.value.status = null;
  selectedFilters.value.accepted = null;
  selectedFilters.value.rejected = null;
  selectedFilters.value.isAccepted = null;
  selectedFilters.value.isCancelled = null;
  if (formateRoutes.value(currentVacancy?.value?.routes)?.length === 1) {
    selectRouteFilter(
      formateRoutes.value(currentVacancy?.value?.routes)[0]?.id
    );
  } else {
    selectedFilters.value.vacancy_route_id = null;
  }
};

const selectVacancyStepFilter = (vacancyStepFilter, type) => {
  if (type) {
    selectedFilters.value.approvalId = vacancyStepFilter?.filter_approval_id;
    selectedFilters.value.stepFilterId = undefined;
    selectedFilters.value.isAccepted = null;
    selectedFilters.value.isCancelled = null;
  } else {
    selectedFilters.value.stepFilterId = vacancyStepFilter?.filter_step_id;
    selectedFilters.value.approvalId = null;
    selectedFilters.value.isAccepted = null;
    selectedFilters.value.isCancelled = null;
  }
};

const selectApprovalFilter = (type) => {
  if (type === "accepted") {
    selectedFilters.value.isAccepted = 1;
    selectedFilters.value.isCancelled = null;
    selectedFilters.value.approvalId = null;
    selectedFilters.value.stepFilterId = null;
  }
  if (type === "rejected") {
    selectedFilters.value.isCancelled = 1;
    selectedFilters.value.isAccepted = 0;
    selectedFilters.value.approvalId = null;
    selectedFilters.value.stepFilterId = null;
  }
};

const selectStage = (step) => {
  selectedFilters.value.step = step;
  selectedFilters.value.isAccepted = null;
  selectedFilters.value.isCancelled = null;
  selectedFilters.value.status = null;
};

const selectStatusFilter = (status) => {
  selectedFilters.value.status = status;
  selectedFilters.value.step = null;
};
watch(
  selectedFilters,
  () => {
    emit("update:modelValue", selectedFilters.value);
  },
  { deep: true }
);

onMounted(() => {
  if (props?.modelValue) {
    selectedFilters.value = props.modelValue;
    if (props?.modelValue?.vacancyId) {
      selectedFilters.vacancyId = props?.modelValue?.vacancyId;
    }
  }
});
</script>

<style scoped lang="scss">
.response-sidebar {
  flex-grow: 0;
  border-radius: 16px;

  :deep(.v-select__options) {
    max-height: 312px;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  &__top-block {
    margin-bottom: 24px;
    padding: 16px 12px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.04);
    border-radius: 10px;

    &_bordered {
      border-radius: 16px;
    }
  }

  &__bottom-block {
    background-color: #fff;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.04);
    border-radius: 10px;

    &_bordered {
      border-radius: 16px;
    }
  }

  &__bottom-buttons {
    padding: 16px 12px 12px;
  }

  &__footer-buttons {
    padding: 12px 12px 16px;
    border-top: solid 1px $light-gray-1;
    background-color: #fff;
    border-radius: 0 0 16px 16px;
  }

  &__title {
    margin-bottom: 12px;
    margin-left: 12px;
    font-size: 18px;
    font-weight: 600;
  }

  &__title-route {
    margin-top: 24px;
    margin-bottom: 12px;
    margin-left: 12px;
    font-size: 18px;
    font-weight: 600;
  }

  &__select {
    margin-top: 12px;

    :deep(.v-select__header-value) {
      padding-right: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow-x: hidden;
      font-weight: 500;
      white-space: nowrap;
    }

    :deep(.v-select__header-palceholder) {
      color: $gray;
    }
    :deep(.v-select__header_selected .v-select__header-palceholder) {
      display: none;
    }

    :deep(.v-select__header_selected .v-select__header-value) {
      top: 16px;
    }
  }

  &__select-routes {
    :deep(.v-select__header-palceholder) {
      color: $gray;
    }
    :deep(.v-select__header_selected .v-select__header-palceholder) {
      display: none;
    }

    :deep(.v-select__header_selected .v-select__header-value) {
      top: 16px;
    }
  }

  &__btns {
    width: 100%;
  }

  &__btn {
    width: 100%;
    height: 44px;
    padding: 0 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:hover {
      background-color: #f0f2f4;
    }

    &_active {
      background-color: #f0f2f4;
    }

    &_with-fio {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      transition: 0.3s;
      cursor: pointer;
    }
  }

  &__btn-row {
    display: flex;
  }

  &__btn-cell {
    display: flex;
    flex-direction: column;
    margin-right: auto;
  }

  &__btn-title {
    font-size: 14px;
    font-weight: 400;
  }

  &__btn-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.86px;
    opacity: 0.7;
  }

  &__btn-fio {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.51px;
  }

  &__btn-num {
    color: $gray;
    font-size: 14px;
    font-weight: 500;
    align-content: center;
  }
}
</style>
